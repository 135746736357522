/* eslint-disable */

import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./scss/common.scss";
import Company from "./pages/company/Company";
import Header from "./pages/common/Header";
import Solution from "./pages/solution/Solution";
import Business from "./pages/business/Business";
import ContentsView from "./pages/modules/ContentsView";
import ContentsWrite from "./pages/modules/ContentsWrite";
import ContentsList from "./pages/modules/ContentsList";
import LightField from "./pages/lightField/LightField";
import Contact from "./pages/etc/Contact";
import Privacy from "./pages/etc/Privacy";
import NewsList from "./pages/news/NewsList";
import CaseStudyList from "./pages/caseStudy/CaseStudyList";
import SupportList from "./pages/support/SupportList";
import Login from "./pages/modules/Login";

// import RouteChangeTracker from './components/RouteChangeTracker';

import $ from "jquery";
import MainLanding from "./pages/main/MainLanding";
import ReactGA from "react-ga";
import SampleList from "./pages/modules/SampleList";
import DhaulaPreorder from "./pages/product/DhaulaPreorder";
import TechList from "./pages/tech/TechList";
import TechView from "./pages/tech/TechView";
import TechWrite from "./pages/tech/TechWrite";
import TechSend from "./pages/tech/TechSend";

function App() {
  //RouteChangeTracker();
  let [lang, setLang] = React.useState("kr");
  let top = false;
  //let history = useLocation();

  React.useEffect(() => {
    console.log("- MOPICLABS -");
    // ReactGA.initialize("user id");
    // history.listen((location) => {
    //   ReactGA.set({ page: location.pathname }); // Update the user's current page
    //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
    //   // ReactGA.pageview(window.location.pathname + window.location.search);
    // });
    window.addEventListener("scroll", (e) => {
      // const scrollHeight = Math.max(
      //   document.documentElement.scrollHeight,
      //   document.body.scrollHeight,
      // );
      const scrollTop = Math.max(
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      // const clientHeight = document.documentElement.clientHeight;

      if (scrollTop > 300 && !top) {
        $(".btn_top").stop().fadeIn(300);
        top = true;
      } else if (scrollTop < 300 && top) {
        $(".btn_top").stop().fadeOut(300);
        top = false;
      }
      $(".btn_top").on("click", function () {
        $("html, body").stop().animate({ scrollTop: "0px" });
      });
    });
  }, []);

  return (
    <div className="App">
      <div className="wrapper ">
        <Header></Header>
        <Routes>
          {/* <Route exact path="/sample" element={<SampleList />}></Route> */}

          <Route path="/" element={<MainLanding lang={"kr"} />}></Route>
          <Route path="/kr/" element={<MainLanding lang={"kr"} />}></Route>
          <Route
            exact
            path="/kr/company"
            element={<Company lang={"kr"} />}></Route>
          <Route
            exact
            path="/kr/lightfield"
            element={<LightField lang={"kr"} />}></Route>
          <Route
            exact
            path="/kr/solution"
            element={<Solution lang={"kr"} />}></Route>
          <Route
            exact
            path="/kr/business"
            element={<Business lang={"kr"} />}></Route>
          <Route
            exact
            path="/kr/casestudy/list"
            element={<CaseStudyList lang={"kr"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/kr/casestudy/write"
            element={<ContentsWrite lang={"kr"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/kr/casestudy/write/:id"
            element={<ContentsWrite lang={"kr"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/kr/casestudy/view/:id"
            element={<ContentsView lang={"kr"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/kr/news/list"
            element={<NewsList lang={"kr"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/kr/news/view/:id"
            element={<ContentsView lang={"kr"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/kr/news/write/"
            element={<ContentsWrite lang={"kr"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/kr/news/write/:id"
            element={<ContentsWrite lang={"kr"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/kr/support/list"
            element={<SupportList lang={"kr"} type={"SUPPORT"} />}></Route>
          <Route
            exact
            path="/kr/support/view/:id"
            element={<ContentsView lang={"kr"} type={"SUPPORT"} />}></Route>
          <Route
            exact
            path="/kr/support/write/"
            element={<ContentsWrite lang={"kr"} type={"SUPPORT"} />}></Route>
          <Route
            exact
            path="/kr/support/write/:id"
            element={<ContentsWrite lang={"kr"} type={"SUPPORT"} />}></Route>

          <Route
            exact
            path="/kr/casestudy_admin/list"
            element={
              <CaseStudyList lang={"kr"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/kr/casestudy_admin/write"
            element={
              <ContentsWrite lang={"kr"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/kr/casestudy_admin/write/:id"
            element={
              <ContentsWrite lang={"kr"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/kr/casestudy_admin/view/:id"
            element={
              <ContentsView lang={"kr"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/kr/news_admin/list"
            element={<NewsList lang={"kr"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/kr/news_admin/view/:id"
            element={<ContentsView lang={"kr"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/kr/news_admin/write/"
            element={<ContentsWrite lang={"kr"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/kr/news_admin/write/:id"
            element={<ContentsWrite lang={"kr"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/kr/support_admin/list"
            element={
              <SupportList lang={"kr"} type={"SUPPORT_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/kr/support_admin/view/:id"
            element={
              <ContentsView lang={"kr"} type={"SUPPORT_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/kr/support_admin/write/"
            element={
              <ContentsWrite lang={"kr"} type={"SUPPORT_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/kr/support_admin/write/:id"
            element={
              <ContentsWrite lang={"kr"} type={"SUPPORT_ADMIN"} />
            }></Route>

          <Route
            exact
            path="/kr/product"
            element={<DhaulaPreorder lang={"kr"} type={"DHAULA"} />}></Route>

          <Route
            exact
            path="/kr/contact"
            element={<Contact lang={"kr"} />}></Route>
          <Route
            exact
            path="/kr/privacy"
            element={<Privacy lang={"kr"} />}></Route>
          <Route exact path="/kr/admin" element={<Login />}></Route>

          <Route path="/en/" element={<MainLanding lang={"en"} />}></Route>
          <Route
            exact
            path="/en/company"
            element={<Company lang={"en"} />}></Route>
          <Route
            exact
            path="/en/lightfield"
            element={<LightField lang={"en"} />}></Route>
          <Route
            exact
            path="/en/solution"
            element={<Solution lang={"en"} />}></Route>
          <Route
            exact
            path="/en/business"
            element={<Business lang={"en"} />}></Route>
          <Route
            exact
            path="/en/casestudy/list"
            element={<ContentsList lang={"en"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/en/casestudy/write"
            element={<ContentsWrite lang={"en"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/en/casestudy/write/:id"
            element={<ContentsWrite lang={"en"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/en/casestudy/view/:id"
            element={<ContentsView lang={"en"} type={"CASESTUDY"} />}></Route>
          <Route
            exact
            path="/en/news/list"
            element={<NewsList lang={"en"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/en/news/view/:id"
            element={<ContentsView lang={"en"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/en/news/write/"
            element={<ContentsWrite lang={"en"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/en/news/write/:id"
            element={<ContentsWrite lang={"en"} type={"NEWS"} />}></Route>
          <Route
            exact
            path="/en/support/list"
            element={<SupportList lang={"en"} type={"SUPPORT"} />}></Route>
          <Route
            exact
            path="/en/support/view/:id"
            element={<ContentsView lang={"en"} type={"SUPPORT"} />}></Route>
          <Route
            exact
            path="/en/support/write/"
            element={<ContentsWrite lang={"en"} type={"SUPPORT"} />}></Route>
          <Route
            exact
            path="/en/support/write/:id"
            element={<ContentsWrite lang={"en"} type={"SUPPORT"} />}></Route>

          <Route
            exact
            path="/en/casestudy_admin/list"
            element={
              <CaseStudyList lang={"en"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/en/casestudy_admin/write"
            element={
              <ContentsWrite lang={"en"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/en/casestudy_admin/write/:id"
            element={
              <ContentsWrite lang={"en"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/en/casestudy_admin/view/:id"
            element={
              <ContentsView lang={"en"} type={"CASESTUDY_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/en/news_admin/list"
            element={<NewsList lang={"en"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/en/news_admin/view/:id"
            element={<ContentsView lang={"en"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/en/news_admin/write/"
            element={<ContentsWrite lang={"en"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/en/news_admin/write/:id"
            element={<ContentsWrite lang={"en"} type={"NEWS_ADMIN"} />}></Route>
          <Route
            exact
            path="/en/support_admin/list"
            element={
              <SupportList lang={"en"} type={"SUPPORT_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/en/support_admin/view/:id"
            element={
              <ContentsView lang={"en"} type={"SUPPORT_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/en/support_admin/write/"
            element={
              <ContentsWrite lang={"en"} type={"SUPPORT_ADMIN"} />
            }></Route>
          <Route
            exact
            path="/en/support_admin/write/:id"
            element={
              <ContentsWrite lang={"en"} type={"SUPPORT_ADMIN"} />
            }></Route>

          <Route
            exact
            path="/en/product"
            element={<DhaulaPreorder lang={"en"} type={"DHAULA"} />}></Route>

          <Route
            exact
            path="/en/contact"
            element={<Contact lang={"en"} />}></Route>
          <Route
            exact
            path="/en/privacy"
            element={<Privacy lang={"en"} />}></Route>

          <Route
            exact
            path="/en/tech/send"
            element={<TechSend lang={"en"} />}></Route>
          <Route
            exact
            path="/en/tech/list"
            element={<TechList lang={"en"} />}></Route>
          <Route
            exact
            path="/en/tech/view/:id"
            element={<TechView lang={"en"} />}></Route>
          <Route
            exact
            path="/ko/tech/send"
            element={<TechSend lang={"ko"} />}></Route>
          <Route
            exact
            path="/ko/tech/list"
            element={<TechList lang={"ko"} />}></Route>
          <Route
            exact
            path="/ko/tech/view/:id"
            element={<TechView lang={"ko"} />}></Route>
          <Route
            exact
            path="/en/tech/write/"
            element={<TechWrite lang={"en"} />}></Route>
          <Route
            exact
            path="/ko/tech/write/"
            element={<TechWrite lang={"ko"} />}></Route>
          <Route
            exact
            path="/en/tech/write/:id"
            element={<TechWrite lang={"en"} />}></Route>
          <Route
            exact
            path="/ko/tech/write/:id"
            element={<TechWrite lang={"ko"} />}></Route>
        </Routes>
      </div>
      <button
        className="btn_top"
        onClick={() => {
          window.scrollTo(0, 0);
        }}>
        <i className="blind">Top</i>
      </button>
    </div>
  );
}

export default App;
